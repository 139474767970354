
<template>

  <div class="contents">
    <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
      <div class="main-card"  >
        <div class="sub-card-top"  >
          <page-title></page-title>
          <span class="parent uk-child-width-1-2@m">
            <div>
              <p class="text">
                愛車を大切にする人のために心を込めて作業いたします。 <br>
                作業には予約が必要ですのでまずはお気軽にご相談ください。  <br>
                ⇒<a  style="text-decoration: underline; color: #FFF;" href="tel:0280236262">0280-23-6262</a>
              </p>
            </div>
            <div class="button-area">
                <router-link to="/contact" class="btn"><span>＞＞＞お問い合わせ</span></router-link>
              </div>
          </span>
        </div>

        <div class="sub-card"  >
          <div class="section-title">
            <span>サービスメニュー </span>
          </div>
          <div class="section-main " >
            <div v-for="(carMaintenance, i) in carMaintenances" :key="i" class="access-list " uk-grid>
              <div class="uk-width-1-4@m uk-visible@s" style="" uk-leader>◆{{carMaintenance.title}}</div>
              <div class="uk-width-3-4@m uk-visible@s">{{carMaintenance.detail}}</div>
              <div class="uk-hidden@s" >{{carMaintenance.title + '⇒' + carMaintenance.detail}}</div>
            </div>
            <div class="access-list " uk-grid>
              <div style="">◆その他、車検/陸送/オークション代行/不要車引取り等、おクルマに関する全てのご相談に応じます。 </div>
            </div>
            <div class="c-btn">
              <router-link to="/contact" class="c-btn-anchor">お問い合わせフォーム</router-link>
              <a href="tel:0280236262" class="c-btn-anchor" target="_blank" rel="noopener noreferrer nofollow">お電話はこちら</a>
            </div>
          </div>
          <link-list></link-list>
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import LinkList from '../../modules/parts/LinkList.vue'

export default {
  components: {
    LinkList,
  },
  created() {
    // メインメニュー作成
    this.carMaintenances = this.commonSetting.carMaintenance;
  },
}
</script>



<style scoped lang="scss">


.parallax-bg {
  background-image:url(~@/assets/img/bg-image1.jpg);
}

.sub-card-top{
  background-image:url(~@/assets/img/page-jigyo-top-vanning.jpg);
}


</style>
